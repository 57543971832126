<template>
    <div id='contacts'>
        <CRow>
            <CCol class="mx-auto" lg="8" md="10">
                <CCard>
                <CCardHeader>
                    <a-icon type="search" />
                    <strong> Contact search </strong>
                </CCardHeader>
                <CCardBody>
                    <CInput 
                        placeholder="Search..."
                        v-on:update:value="addSearchValue"
                        >
                    <template #prepend>
                    </template>
                    </CInput>
                        </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <ul v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span></p>
            </div>
        </ul>
        <div>
            <CRow>
                <CCol class="mx-auto" lg="8" md="10">
                    <CCard>
                    <CCardHeader>
                        <a-icon type="mail" />&nbsp;
                        <a-icon type="phone" />
                        <strong> Contacts </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup >
                            <div v-for="(contact) in contacts" :key="contact.contactId">
                                <ContactRow 
                                    :contactId="contact.contactId" 
                                    :name="contact.name" 
                                    :givenNames="contact.givenNames" 
                                    :surname="contact.surname" 
                                    :email="contact.email" 
                                    :phone="contact.phone" 
                                    :address1="contact.address1" 
                                    :address2="contact.address2" 
                                    :city="contact.city" 
                                    :postcode="contact.postcode" 
                                    :preferredContactType="contact.preferredContactType" 
                                    :createdDate="contact.createdDate" 
                                    />
                            </div>
                        </CListGroup>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ContactRow from '@/components/ContactRow.vue'



export default {
    name: 'Contacts',
    components: {
        ContactRow
    },
    data() {
        return {
            contacts: null,
            fetching: false,
            searchValue: null
        }
    },
    methods: {
        fetchContacts () {
            console.log('Start fetchContacts with searchValue: ' + this.searchValue)
            if (typeof this.searchValue === 'undefined' || this.searchValue == null || this.searchValue.length < 1) {
                this.getContacts()
                return
            }
            let email = null
            let phone = null
            let name = null
            if (this.isaPhoneNumber(this.searchValue, 3)) {
                phone = this.searchValue.trim()
            } else {
                // if (this.searchValue.indexOf('@') >= 0) {
                    email = this.searchValue
                // }
                name = this.searchValue
            }
            this.getMatchedContacts(name, email, phone)
        },
        getContacts () {
            console.log('Start getContacts with searchValue: ' + this.searchValue)
            console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
            const url = process.env.VUE_APP_CRM_API_ROOT + '/contacts';
            let params = {}
            this.fetching = true;

            this.$cognitoAuth.getIdToken((err, result) => {
                if (err) { 
                    this.error = err;
                    delete this.$http.defaults.headers.common['Authorization'];
                    this.fetching = false;
                } else {
                    // const url = config.s3SignedUrl;
                    console.log('Contacts, axios token: ' + result)
                    this.$http.defaults.headers.common['Authorization'] = result;
                    axios({ method: 'get', url: url, params: params})
                        .then(response => {
                            console.log('getContacts, response: ' + JSON.stringify(response.data))
                            this.contacts = response.data
                            this.fetching = false;
                        })
                        .then(status => {
                            console.log('getContacts, axios status: ' + status)
                            this.status = status;
                        })
                        .catch(err => {
                            this.fetching = false;
                            console.log('getContacts, axios err: ' + err)
                            console.log('err properties...')
                            Object.keys(err).forEach((prop)=> console.log(prop));
                            this.error = err;
                            console.log('err.request properties...')
                            Object.keys(err.request).forEach((prop)=> console.log(prop));

                        })
                }
            })
        },
        getMatchedContacts (name, email, phone) {
            console.log('Start getContactMatches with searchValue: ' + this.searchValue)
            console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
            const url = process.env.VUE_APP_CRM_API_ROOT + '/contactmatches';
            let params = {}
            if (typeof phone !== 'undefined' && phone != null) {
                params.phone = phone
            }
            if (typeof email !== 'undefined' && email != null) {
                params.email = email
            }
            if (typeof name !== 'undefined' && name != null) {
                params.name = name
            }
            console.log('getContactMatches with params: ' + JSON.stringify(params))
            this.fetching = true;

            this.$cognitoAuth.getIdToken((err, result) => {
                if (err) { 
                    this.error = err;
                    delete this.$http.defaults.headers.common['Authorization'];
                    this.fetching = false;
                } else {
                    // const url = config.s3SignedUrl;
                    console.log('Contacts, axios token: ' + result)
                    this.$http.defaults.headers.common['Authorization'] = result;
                    axios({ method: 'get', url: url, params: params})
                        .then(response => {
                            console.log('getContactMatches, response: ' + JSON.stringify(response.data))
                            if (typeof response.data === 'undefined' || response.data == null) {
                                console.log('getContactMatches: no data in response')
                                this.contacts = []
                                this.fetching = false
                                return
                            }
                            console.log('getContactMatches: contactMatches: ' + JSON.stringify(contactMatches))
                            let contactMatches = response.data.matchedContacts
                            let orderedContacts = []
                            contactMatches.sort(function(a, b) {return a.matchScore - b.matchScore;})
                            for(var i = 0; i < contactMatches.length; i++) {
                                orderedContacts.push(contactMatches[i].contact)
                            }
                            console.log('orderedContacts: ' + JSON.stringify(orderedContacts))
                            this.contacts = orderedContacts
                            this.fetching = false;
                        })
                        .then(status => {
                            console.log('getContactMatches, axios status: ' + status)
                            this.status = status;
                        })
                        .catch(err => {
                            this.fetching = false;
                            console.log('getContactMatches, axios err: ' + err)
                            console.log('err properties...')
                            Object.keys(err).forEach((prop)=> console.log(prop));
                            this.error = err;
                            console.log('err.request properties...')
                            Object.keys(err.request).forEach((prop)=> console.log(prop));

                        })
                }
            })
        },
        isaPhoneNumber(s, minLength) {
            console.log('check phone number: ' + s)
            s = s.trim()
            console.log('check phone number trimmed: ' + s)
            if (s.charAt(0) == '+') {
                s = s.substring(1)
            }
            console.log('check phone number: ' + s + ', length is at least: ' + minLength)
            if (s.length < minLength) {
                return false
            }
            console.log('phone number isInt(s) returns: ' + this.isInt(s))
            return this.isInt(s)
        },
        addSearchValue(event) {
            console.log('addSearchValue: ' + event)
            // this.resetErrors()
            this.searchValue = event
            this.fetchContacts()
        },
        isInt(value) {
            return !isNaN(value) && 
                parseInt(Number(value)) == value && 
                !isNaN(parseInt(value, 10));
        },
        compareMatchedContacts(match1, match2) {
            return match1.matchScore - match2.matchScore;
        }

    },

    mounted () {
        console.log('Contacts:mounted')
        this.fetchContacts()

    },
    created () {
        console.log('Contacts:created')
    },
    updated () {
        console.log('Contacts:updated')
    }
}

</script>


<style scoped>

</style>
