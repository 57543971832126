<template>
    <div id='contactRow'>
        <CListGroupItem
            :to="{ name: 'contact', params: { contactId: contactId }}"
            class="flex-column align-items-start"
        >
            <CRow>
                    <div v-if="name">
                <CCol>
                        <strong>{{name}}</strong>&nbsp;
                        </CCol>
                    </div>
                    <div v-if="!name && givenNames && surname">
                <CCol>
                        <strong>{{surname}}, {{givenNames}}</strong>&nbsp;
                        </CCol>
                    </div>
                    <div v-if="!name && givenNames && !surname">
                <CCol>
                        <strong>{{givenNames}}</strong>&nbsp;
                        </CCol>
                    </div>
                    <div v-if="!name && !givenNames && surname">
                <CCol>
                        <strong>{{surname}}</strong>&nbsp;
                        </CCol>
                    </div>
                        <div v-if="name && givenNames && surname">
                <CCol>
                            ({{surname}}, {{givenNames}})&nbsp;
                        </CCol>
                        </div>
                        <div v-if="name && givenNames && !surname">
                <CCol>
                            ({{givenNames}})&nbsp;
                        </CCol>
                        </div>
                        <div v-if="name && !givenNames && surname">
                <CCol>
                            ({{surname}})&nbsp;
                        </CCol>
                        </div>
            </CRow>
            <CRow>
                    <div v-if="email">
                <CCol>
                        <a-icon type="mail" /> {{email}}&nbsp;
                </CCol>
                    </div>
                    <div v-if="phone">
                <CCol>
                        <a-icon type="phone" /> {{phone}}
                </CCol>
                    </div>
            </CRow>
        </CListGroupItem>
</div>
</template>

<script>

export default {
    name: 'ContactRow',
    components: {
        
    },
    methods: {
    },
    props: {
        contactId: Number,
        name: String,
        givenNames: String,
        surname: String,
        email: String,
        phone: String,
        address1: String,
        address2: String,
        city: String,
        postcode: String,
        preferredContactType: String,
        createdDate: String
    }
}

</script>


